import { radioButtonInput } from "../Styles";
import React from "react";
import { isEmpty, useDataProvider, useNotify } from "ra-core";
import { useFormContext } from "react-hook-form";
import CustomDialog from "../../utils/CustomDialog";
import { RadioButtonGroupInput } from "ra-ui-materialui";
import { TooltipDescription } from "../common/TooltipDescription";

const PersonaTemplateDialogForDefaultTemplate = (props) => {
  const { 
    allTemplates,     
    mappedTemplates, 
    setMappedTemplates,
    open, 
    handleDialogClose,
    handleParentDialogClose,
    isEdit, 
    clientId, 
    } = props;     
  
  const [defaultTemplate, setDefaultTemplate] = React.useState(null);    
    
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const form = useFormContext();

  const personaTemplatesFormValue = form.getValues()?.personaTemplates;
  const defaultPersonaTemplateFormValue = form.getValues()?.defaultPersonaTemplate;

  const selectedTemplateChoices = allTemplates?.filter(template => personaTemplatesFormValue?.includes(template.id));
  const defaultValue = mappedTemplates?.find(template => template?.defaultForClient)?.templateId;  

  const handleSubmit = () => {
    if(isEmpty(defaultPersonaTemplateFormValue)) {
      notify('Please select a default identity template', { type: 'warning', autoHideDuration: 1000 });
    }else{
      dataProvider
      .updateAssignmentOfPersonaTemplates({
        templates: personaTemplatesFormValue,
        clientId: clientId,
        defaultPersonaTemplate: defaultPersonaTemplateFormValue
      })
      .then(({ data }) => {
        setMappedTemplates(data);
        notify('Identity Templates Mapped.', { type: 'success', autoHideDuration: 1000 });
      })
      .catch((error) => {
        notify('Something went wrong while updating the identity templates of client', { type: 'warning', autoHideDuration: 1000 });
      })
      handleDialogClose();
      handleParentDialogClose();
    }
  }

  const handleSubmitForCreate = () => {
    if(!isEmpty(defaultTemplate)) {
      form.setValue("personaTemplates", personaTemplatesFormValue);
      form.setValue("defaultPersonaTemplate", defaultTemplate);
      handleDialogClose();
      handleParentDialogClose();
    }else{
      notify('Please select a default identity template', { type: 'warning', autoHideDuration: 1000 });
    }    
  }

  return(
    <CustomDialog
        openDialog={open}
        closeDialog={handleDialogClose}
        title="Select Default Identity Template"
        content = {
          <RadioButtonGroupInput 
            source="defaultPersonaTemplate"
            label="Default Identity Template"
            choices={selectedTemplateChoices}
            onChange={(e) => {setDefaultTemplate(e.target.value)}}
            defaultValue={isEdit ? defaultValue : defaultTemplate}
            sx={radioButtonInput}
            optionText={<TooltipDescription />}
          />
        }
        handleSubmit={(isEdit) ? handleSubmit : handleSubmitForCreate}
      />
  );
}

export default PersonaTemplateDialogForDefaultTemplate;