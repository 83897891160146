import { Grid } from '@mui/material'
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import {
  SelectInput,
  Create,
  SimpleForm,
  useNotify,
  Loading,
  required,
  useResourceContext
} from "react-admin";
import { useWatch } from 'react-hook-form';
import React from "react";
import { isSchedulingSupported, httpClient } from "../DataProvider";

import {
  ActionTitles,
  RESOURCES,
  RESOURCES_BASEPATH,
  TemplateType
} from "../Constants";
import ListActions from "../common/ListActions";
import CustomToolbar from "../common/FormToolbar";
import { extractOriginalRequestIdFromURL, extractQueryParamsFromURL } from "../../mappers/Report";
import { useLocation } from "react-router";
import { contentCenter, padding0, padding2, simpleForm, width } from '../Styles';
import { CreateElementsPrimaryReportGrid } from "./CreateElementsPrimaryReportGrid";
import { CreateElementsSecondaryReportGrid } from "./CreateElementsSecondaryReportGrid";

export const ReportToolbar = (props) => {
  const values = useWatch();

  return (
    <CustomToolbar
      {...props}
      buttontitle1={ActionTitles.CREATE_AND_ADD_ANOTHER}
      buttontitle2={ActionTitles.CREATE_REPORT}
      forReports
      isScheduled={isSchedulingSupported(values.reportType, values.frequency)}
      resource={RESOURCES.REPORTS}
      basePath={RESOURCES_BASEPATH.REPORTS}
    />
  );
};

export const ReportCreate = () => {
  const location = useLocation();

  const enableSecondCreateReportPage = extractQueryParamsFromURL(location?.search, 'secondCreateReport');
  const originalClientRequestId = enableSecondCreateReportPage && extractOriginalRequestIdFromURL(location?.pathname);

  return (
    <Create
      actions={<ListActions title={ActionTitles.CREATE_REPORT} renderButtonAndSearch={false} />}
      sx={contentCenter}
    >
      <CreateReportForm
        toolbar={<ReportToolbar basePath={RESOURCES_BASEPATH.REPORTS} hideButton2={enableSecondCreateReportPage} enableSecondCreateReportPage={enableSecondCreateReportPage} />}
        enableSecondCreateReportPage={enableSecondCreateReportPage}
        originalClientRequestId={originalClientRequestId}
       />
    </Create>
  );
};

export const CreateReportForm = (props) => {
  //intially show fields based on default template
  const [loading, setLoading] = React.useState(true);
  const [fields, setFields] = React.useState([]);
  const notify = useNotify();
  const resource = useResourceContext();

  let reportTemplates = JSON.parse(props.enableSecondCreateReportPage ? localStorage.getItem('secondReportTemplates') : localStorage.getItem('reportTemplates'));

  if(resource===RESOURCES.IDENTITY_VERIFICATION){    
    reportTemplates = reportTemplates.filter(template => template.templateType === TemplateType.IDENTITY_REPORT_TEMPLATE);    
  }

  const parsedTemplate = JSON.parse(localStorage.getItem('defaultTemplates')).filter(template => props.enableSecondCreateReportPage ?
    (template.templateType === TemplateType.EXPAND_REPORT_TEMPLATE) : (template.templateType === TemplateType.CREATE_REPORT_TEMPLATE));
  const defaultTemplateId = parsedTemplate?.[0]?.templateId;

  // intial template should be any of the templates mapped to client or the default template in case nothing is mapped
  const [selectedTemplateId, setSelectedTemplateId] = React.useState(
    reportTemplates.length > 0 ? reportTemplates[0].id : defaultTemplateId
  );

  React.useEffect(() => {
    async function fetchData() {
      const apiUrl = process.env.REACT_APP_API_ENDPOINT;
      const template = `${apiUrl}/template/${selectedTemplateId}`;
      await httpClient(template, { method: 'GET' })
        .then(({ json }) => {
          setFields(json.data.templateData.fields);
          setLoading(false);
        })
        .catch((error) => {
          // incase of an error, show notification and switch to default template fields which were loaded initially while login
          notify('Something went wrong while fetching the template.', { type: 'info', autoHideDuration: 1000 });
          setFields(parsedTemplate.templateData.fields);
          setLoading(false);
        });
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTemplateId, notify]);

  const setTemplateValue = (event) => {
    setSelectedTemplateId(event.target.value);
  };

  if (loading) return <Loading />;

  return (
    <SimpleForm
      warnWhenUnsavedChanges
      toolbar={props.toolbar}
      mode="onBlur"
      reValidateMode="onBlur"
      sx={simpleForm}
    >
      {
        <Grid container flexGrow={1}>
          <Grid xs={12}>
            <List sx={padding0.y}>
              {reportTemplates.length > 1 && (
                <React.Fragment>
                  <ListItem sx={{ ...padding0.top, ...padding2.bottom }}>
                    <Grid container alignItems="center">
                      <Grid xs={0} md={2}></Grid>
                      <Grid xs={12} md={props.isMatterShow ? 12 : 8}>
                        <SelectInput
                          label="Template For Report"
                          fullWidth={true}
                          defaultValue={selectedTemplateId}
                          source="selectedTemplate"
                          name="selectedTemplate"
                          sx={{ ...width.fluid.w100, marginBottom: "-15px" }}
                          onChange={setTemplateValue}
                          choices={reportTemplates}
                          validate={required()}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                  </ListItem>
                  <Divider light />
                </React.Fragment>
              )}
              {props.enableSecondCreateReportPage ?
                <CreateElementsSecondaryReportGrid
                  selectedTemplateId={selectedTemplateId}
                  originalClientRequestId={props.originalClientRequestId}
                  fields={fields}
                />
                :
                <CreateElementsPrimaryReportGrid
                  selectedTemplateId={selectedTemplateId}
                  fields={fields}
                  isMatterShow={props.isMatterShow}
                  matterId={props.matterId}
                  accounts={props.accounts}
                  matterName={props.matterName}
                  startDate={props.startDate}
                  endDate={props.endDate}
                />
              }
            </List>
          </Grid>
        </Grid>
      }
    </SimpleForm>
  );
};