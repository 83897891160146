import { useState, useEffect } from "react";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { trueCircleIcon, falseCircleIcon } from "../common/StyledIcons";
import {
  List,
  TextField,
  BooleanField,
  ShowButton,
  FunctionField,
  useDataProvider,
  Loading,
  useNotify,
  useRefresh,
  DatagridConfigurable,
  SelectField,
  Button,
  useRedirect,
  downloadCSV,
} from 'react-admin'
import { ActionTitles, IDENTITY_VERIFICATION_REQUEST_STATUS_CHOICES, REPORT_TYPES, RESOURCES, RESOURCES_BASEPATH, RESOURCES_META_DATA } from "../Constants";
import ListActions from "../common/ListActions";
import CustomDeleteWithConfirmButton from "../../utils/CustomDeleteWithConfirmButton";
import CustomTooltip from "../../utils/CustomTooltip";
import PreviewLink from "../../utils/PreviewLink";
import DownloadLink from "../../utils/DownloadLink";
import CustomDialog from "../../utils/CustomDialog";
import React from "react";
import { isAdminUser } from "../DataProvider";
import isUndefined from "lodash/isUndefined";
import { CustomPagination } from "../common/CustomPaginationComponent";
import {CustomDataGridHeader, onToggleDataGridHeader} from '../common/CustomDataGridHeader'
import {
  cellContentCentered,
  dataGrid,
  ellipsis,
  iconAction,
  listRoot,
  textColor,
} from '../Styles'
import defaultsDeep from 'lodash/defaultsDeep';
import cloneDeep from 'lodash/cloneDeep';
import { Error404 } from "../errors/Error404";
import { IdentityAdminFilter, IdentityFilter } from "./IdentityFilter";
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import CancelIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircleOutlined';
import { Grid, Tooltip } from "@mui/material";
import { parseIdRecordsforExport } from "../../mappers/IdentityVerification";
import jsonExport from 'jsonexport/dist';


export const IdReports = (props) => {
  const isAdmin = isAdminUser();
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedRecordId, setSelectedRecordId] = useState(null);

  const dataProvider = useDataProvider();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [clientId, setClientId] = useState(undefined);
  const [disableDelete, setDisableDelete] = useState(false);  
  let dataGridsLS = JSON.parse(localStorage.getItem("dataGrids"))
  if (!dataGridsLS)
    dataGridsLS = { "ids": true }
  const [dataGrids, setDataGrids] = useState(dataGridsLS ? dataGridsLS : {});

  useEffect(() => {
    if(!isAdmin){
        dataProvider
        .getMappedUsersSummary(`client/mapped-users`)
        .then(({ data }) => {
          setData(data);
          setLoading(false);
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataProvider]);

  useEffect(()=>{
    if(isAdmin && !isUndefined(clientId)){
      dataProvider
      .getMappedUsersbyClientId({clientId : clientId})
      .then(({ data }) => {
        setData(data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }
  }, [clientId, dataProvider, isAdmin])      

  const onDeleteSuccess = () => {
    setDisableDelete(false);
    notify("Request Deleted", { type: 'info', autoHideDuration: 1000 });
    refresh();
  };

  const onDeleteFailure = () => {
    setDisableDelete(false);
    notify("Unable to delete a request", { type: 'warning', autoHideDuration: 1000 });
    refresh();
  };
  
  const pauseContinuousMonitoring = () => {
    dataProvider
    .pauseContinuousMonitoringForAllReport({ id: selectedRecordId })
    .then(() => {      
      notify('Continuous monitoring disabled for all reports', "info", { autoHideDuration: 1000 });
      handleDialogClose();
      refresh();
    })
    .catch(() => {
      notify('Something went wrong while disabling continuous monitoring', "warning", { autoHideDuration: 1000 });
      handleDialogClose();
    });
  }

  const handleDialogClose = () => {
    setDialogOpen(false);    
  };

  const handleIconClick = (recordId) => {    
    setSelectedRecordId(recordId);
    setDialogOpen(true);
  };

  const exporter = (records) => {    
      const list = parseIdRecordsforExport(records, data);
      
      return jsonExport(list, (err, csv) => {
          downloadCSV(csv, RESOURCES_META_DATA[RESOURCES.IDENTITY_VERIFICATION].displayName);        
      });  
    };

  if (loading && !isAdmin) return <Loading />;
  if (error) return <Error404 />;

  return (
    <List
      sx={listRoot}
      actions={
        <ListActions
          title={ActionTitles.IDENTITY_VERIFICATIONS}
          basePath={RESOURCES_BASEPATH.IDENTITY_VERIFICATION}
          renderButtonAndSearch={true}
          showCreateButton={true}
          ShowExportButton={true}
          showColumnsButton={true}
          filters={isAdmin ? <IdentityAdminFilter requestingUserNames={data} setClientId={setClientId} /> : <IdentityFilter requestingUserNames={data}/>}
        />
      }
      sort={{ field: 'updatedAt', order: 'DESC' }}      
      pagination={<CustomPagination />}
      empty={false}
      exporter={exporter}
    >
      <DatagridConfigurable
        header={
          <CustomDataGridHeader
            fixedColumn={dataGrids['ids']}
            onToggle={() => onToggleDataGridHeader('ids', dataGrids, setDataGrids)}
          />
        }
        sx={
          defaultsDeep(
            cloneDeep(dataGrid.base),
            cloneDeep(dataGrid.last3ColsActions),
            dataGrids['ids'] ? cloneDeep(dataGrid.firstColSticky) : {}
          )
        }
        bulkActionButtons={false}
      >        
        <TextField source="name" label="CONTACT NAME" sortable={false} />
        <TextField sx={textColor.primary} source="emailAddress" label="EMAIL ADDRESS" sortable={false} />
        <FunctionField
          sx={cellContentCentered}
          label="PREVIEW"
          source="status"
          title="preview"
          sortable={false}
          render={(record) => (
            <PreviewLink
              clientRequestId={record['clientRequestId']}
              clientId={record['clientId']}
              status={record['identityRequestStatus']}
              resource={RESOURCES.IDENTITY_VERIFICATION}
            />
          )}
        />
        <FunctionField
          sx={cellContentCentered}
          source="status"
          label="DOWNLOAD"
          sortable={false}
          render={(record) => 
          <DownloadLink 
            columns={true} 
            clientRequestId={record['clientRequestId']}
            clientId={record['clientId']}
            status={record['identityRequestStatus']}
            resource={RESOURCES.IDENTITY_VERIFICATION}
            contactName={record['name']}
            requestId={record['reportRequestId']}
            reportType={REPORT_TYPES.IDENTITY_VERIFICATION}            
            {...record}            
          />}
        />        
        <FunctionField
          source="descriptionOfMatter"
          label="DESCRIPTION OF MATTER"
          sortable={false}
          render={(record) => {
            return (
              <Grid item sx={{...ellipsis, maxWidth: "250px"}}>
                <Tooltip title={record.descriptionOfMatter} placement="top">
                  {record.descriptionOfMatter}
                </Tooltip>
              </Grid>
            );
          }}
        />
        <TextField source="clientReference" label="CLIENT REFERENCE" sortable={false} />
        <FunctionField
          sx={cellContentCentered}
          label="CONTINUOUS MONITORING"
          sortable={false}
          render={(record) => (
            <> 
              {record.continuousMonitoringEnabled 
                ?
                 isAdmin 
                 ?
                  <CheckCircleIcon sx={{...textColor.green}} />
                 : 
                  <CustomTooltip title="Click to disable continuous monitoring" placement="top">
                    <CheckCircleIcon 
                      sx={{...textColor.green, cursor: 'pointer'}} 
                      onClick={!isAdmin && (() => handleIconClick(record.id))}
                    />
                  </CustomTooltip>                                   
                : 
                <CancelIcon sx={textColor.red} />
              }
            </> 
          )}
        />
        <BooleanField
          source="hasUnAcknowledgedMatch"
          label="MATCH"
          sx={cellContentCentered}
          TrueIcon={trueCircleIcon}
          FalseIcon={falseCircleIcon}
          sortable={false}
        />
        <SelectField choices={IDENTITY_VERIFICATION_REQUEST_STATUS_CHOICES} source="identityRequestStatus" label="IDENTITY STATUS" sortable={false}/>
        <BooleanField
          source="isPEPReportPresent"
          label="PEP REPORT"
          sx={cellContentCentered}
          TrueIcon={trueCircleIcon}
          FalseIcon={falseCircleIcon}
          sortable={false}
        />
        <BooleanField
          source="isWatchListReportPresent"
          label="WATCHLIST REPORT"
          sx={cellContentCentered}
          TrueIcon={trueCircleIcon}
          FalseIcon={falseCircleIcon}
          sortable={false}
        />
        <BooleanField
          source="isAdverseMediaReportPresent"
          label="ADVERSE MEDIA REPORT"
          sx={cellContentCentered}
          TrueIcon={trueCircleIcon}
          FalseIcon={falseCircleIcon}
          sortable={false}
        />
        <TextField source="createdAt" label="CREATED" sortable={true} />
        <TextField source="updatedAt" label="LAST MODIFIED" sortable={true} />        
        <FunctionField
          render={(record) =>
            !isUndefined(data) && data.get(record.requestingUserId) != null ? data.get(record.requestingUserId) : ' '
          }
          label="REQUESTING USER NAME"
          sortable={false}
        />
        <FunctionField
          sx={cellContentCentered}
          label={false}
          sortable={false}
          render={(record) => (
            <CustomTooltip title="View Request" placement="top">
              <Button
                sx={iconAction}
                size="medium"
                label={""}
                onClick={() => {
                  redirect(
                    isAdmin 
                    ? `${RESOURCES_BASEPATH.REPORTS}/${encodeURIComponent('client/' + clientId + '/detail/' + record['clientRequestId'])}/show` 
                    : `${RESOURCES_BASEPATH.REPORTS}/${record['clientRequestId']}/show`);
                }}
              >
                <DescriptionOutlinedIcon sx={textColor.black} />
              </Button>
            </CustomTooltip>
          )}
        />
        <FunctionField
          sx={cellContentCentered}
          source="View"
          label={false}
          sortable={false}
          render={(record) => (
            <CustomTooltip title="View" placement="top">
              <ShowButton
                icon={<RemoveRedEyeOutlinedIcon />}
                sx={iconAction}
                size="medium"
                label={""}
                basePath={RESOURCES_BASEPATH.IDENTITY_VERIFICATION}
                record={record}
              ></ShowButton>
            </CustomTooltip>
          )}
        />
        <FunctionField
          sx={cellContentCentered}
          source="Delete"
          label={false}
          sortable={false}
          render={(record) => (
            <CustomTooltip title="Delete" placement="top">
              <CustomDeleteWithConfirmButton
                icon={<DeleteOutlinedIcon />}
                sx={iconAction}
                size="medium"
                label={""}
                disabled={isAdmin}
                onSuccess={onDeleteSuccess}
                record={record}
                recordName="Report Request"
                mutationMode="pessimistic"
                resource={RESOURCES.IDENTITY_VERIFICATION}
                onFailure={onDeleteFailure}
                disableDelete={disableDelete}
                setDisableDelete={setDisableDelete}
              />
            </CustomTooltip>
          )}
        />
      </DatagridConfigurable>
      {!isAdmin && 
        <CustomDialog
          openDialog={dialogOpen}
          closeDialog={handleDialogClose}
          title="Continuous Monitoring Details"
          content={<p>Are you sure you want to disable continuous monitoring for all reports?</p>}
          submitBtnTitle="DISABLE MONITORING"                
          handleSubmit={pauseContinuousMonitoring}
          hideDivider={true}
        />
      }
    </List>
  );
};
