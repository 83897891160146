import { Grid, List, ListItem, ListItemText } from "@mui/material";
import { accordion, accordionActionButton, checkBoxInput, padding0 } from "../Styles";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import React, { useEffect, useState } from "react";
import { isEmpty, useDataProvider, useNotify } from "ra-core";
import { useFormContext } from "react-hook-form";
import CustomDialog from "../../utils/CustomDialog";
import { Button, CheckboxGroupInput } from "ra-ui-materialui";
import { parsePersonaTemplateObjectToList } from "../../mappers/IdentityTemplate";
import PersonaTemplateDialogForDefaultTemplate from "./PersonaTemplateDialogForDefaultTemplate";
import { TooltipDescription } from "../common/TooltipDescription";


const PersonaTemplateDialog = (props) => {
  
  const [open, setOpen] = useState(false);
  const [defaultTemplateDialogOpen, setDefaultTemplateDialogOpen] = useState(false);
  const [allTemplates, setAllTemplates] = useState([]);
  const [selectedTemplates, setSelectedTemplates] = useState([]);

  const notify = useNotify();
  const form = useFormContext();
  const dataProvider = useDataProvider();

  const personaTemplatesFormValue = form.getValues()?.personaTemplates;
  const defaultPersonaTemplateFormValue = form.getValues()?.defaultPersonaTemplate;

  useEffect(() => {
    fetchTemplates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const handleClick = () => {
    if(props.isEdit){
      form.setValue("personaTemplates", parsePersonaTemplateObjectToList(props.mappedTemplates));
    }    
    setOpen(true);
  };
  
  const handleDialogClose = () => {
    setOpen(false);
  };

  const handleDefaultTemplateDialogClose = () => {    
    setDefaultTemplateDialogOpen(false);    
  }

  const handleDefaultTemplateDialogOpen = () => {
    const defaultForClient = props.mappedTemplates?.find(template => template?.defaultForClient)?.templateId;

    if(isEmpty(personaTemplatesFormValue)){
      notify('Please select at least one identity template', { type: 'warning', autoHideDuration: 1000 });      
    }else if(personaTemplatesFormValue.length === 1){
      if(props.isEdit){
        dataProvider
        .updateAssignmentOfPersonaTemplates({
          templates: personaTemplatesFormValue,
          clientId: props.clientId,
          defaultPersonaTemplate: personaTemplatesFormValue[0]
        })
        .then(({ data }) => {
          props.setMappedTemplates(data);
          notify('Identity Templates Mapped.', { type: 'success', autoHideDuration: 1000 });
        })
        .catch((error) => {
          notify('Something went wrong while updating the identity templates of client', { type: 'warning', autoHideDuration: 1000 });
        })
      }else{
        form.setValue("defaultPersonaTemplate", personaTemplatesFormValue[0]);
        form.setValue("personaTemplates", personaTemplatesFormValue);        
      }  
      handleDialogClose();      
    }else{
      props.isEdit && form.setValue("defaultPersonaTemplate", defaultForClient);
      setDefaultTemplateDialogOpen(true);
    }    
  }

  const fetchTemplates = () => {
    dataProvider
      .getAllPersonaTemplates()
      .then(({ data }) => {
        setAllTemplates(data);
      })
      .catch((error) => {
        notify('Something went wrong while fetching the identity templates', { type: 'warning', autoHideDuration: 1000 });
      })
  }

  const getTemplateById = (templateId) => {    
    const templateObject = allTemplates.find(template => templateId === template.id)
    return templateObject ? templateObject.name : null
  }
  
  const handleSubmit = () => {
    handleDefaultTemplateDialogOpen();
  }
  
  return(
    <>
      <Grid container>
        <Grid item display={props.isEdit ? "none" : "flex"} xs={12} md={8}>
          <List sx={padding0.y}>
            {defaultPersonaTemplateFormValue 
            && personaTemplatesFormValue 
            && personaTemplatesFormValue.includes(defaultPersonaTemplateFormValue) &&                                       
              <ListItem sx={{...accordion.listItem, "&:first-of-type": { borderTop: 0 } }} key={"defaultPersonaTemplate"}>                
                <ListItemText primary={getTemplateById(defaultPersonaTemplateFormValue) + " (Default)"} disableTypography sx={{ fontWeight : "bold" }}/>
              </ListItem>              
            }
            {personaTemplatesFormValue 
            && personaTemplatesFormValue
            ?.filter(template => template !== defaultPersonaTemplateFormValue)            
            .map((template) => {                           
              return(
                <ListItem sx={{...accordion.listItem, "&:first-of-type": { borderTop: 0 } }} key={template}>
                  <ListItemText primary={getTemplateById(template)} disableTypography />
                </ListItem>
              )
            })}
          </List>
        </Grid>
        <Grid item xs={12} md={props.isEdit ? 12 : 4}>
          <Button
            sx={{...accordionActionButton, marginTop: "15px !important"}}
            label="Add Templates"
            size="small"
            onClick={handleClick}
          >
            <AddCircleOutlineIcon />
          </Button>
        </Grid>
      </Grid>
      <CustomDialog
        openDialog={open}
        closeDialog={handleDialogClose}
        title="Map Identity Templates To Client"
        content = {
          <CheckboxGroupInput
            label="Identity Templates"
            source="personaTemplates"
            choices={allTemplates}
            variant="outlined"
            fullWidth={true}
            defaultValue={props.isEdit ? parsePersonaTemplateObjectToList(props.mappedTemplates): selectedTemplates}
            onChange={(e) => {setSelectedTemplates(e)}}
            sx={checkBoxInput}
            optionText={<TooltipDescription />}   
          />
        }
        handleSubmit={handleSubmit}
      />
      <PersonaTemplateDialogForDefaultTemplate
        open={defaultTemplateDialogOpen}
        handleDialogClose={handleDefaultTemplateDialogClose}
        handleParentDialogClose={handleDialogClose}        
        allTemplates={allTemplates}
        mappedTemplates={props.mappedTemplates}        
        setMappedTemplates={props.setMappedTemplates}
        isEdit={props.isEdit}
        clientId={props.clientId}        
      />
    </>
  );
}

export default PersonaTemplateDialog;