import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { dialogContent, margin0, padding3, topActionButtonBaseStyle } from '../components/Styles'
import { ActionTitles } from '../components/Constants'
import CancelIcon from '@mui/icons-material/CancelOutlined'
import { Button } from 'react-admin'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import { Divider } from "@mui/material";
import { CircularProgress } from '@mui/material'

function CustomDialog(props) {
  const {
    openDialog,
    closeDialog,
    handleSubmit,
    title,
    content,
    dialogPaperProps,
    invalid,
    cancelBtnTitle,
    submitBtnTitle,
    submitBtnIcon,
    hideDialogActions,
    hideSubmitBtn,
    loading,
    disableCancelButton,
    disableSubmitButton,
    hideDivider,
  } = props;

  const [disabled, setDisabled] = useState(false);

  return (
    <>
      <Dialog
        disablebackdropclick
        disableEscapeKeyDown
        open={openDialog}
        onClose={closeDialog}
        PaperProps={dialogPaperProps ? dialogPaperProps : {}}
        fullWidth={true}
      >
        {title && <DialogTitle>{title}</DialogTitle>}
        <DialogContent sx={dialogContent}>
          {content}
        </DialogContent>
        {!hideDialogActions &&
          <>
            { !hideDivider && <Divider light /> }
            <DialogActions sx={padding3}>
              <Button
                variant="outlined"
                size="small"
                sx={{ ...topActionButtonBaseStyle("gray", "#fff", "#eaeaea", "#b2afaf", "#9B9999") }}
                onClick={closeDialog}
                disabled={disableCancelButton}
                label={cancelBtnTitle ? cancelBtnTitle : ActionTitles.CANCEL}
              >
                <CancelIcon />
              </Button>
              {!hideSubmitBtn &&
                <Button
                  sx={{ ...topActionButtonBaseStyle(), ...margin0.right }}
                  variant="contained"
                  size="small"
                  onClick={(e) => {
                    setDisabled(true);
                    handleSubmit(e);
                    setTimeout(() => setDisabled(false), 500);
                  }}
                  disabled={invalid || disabled || disableSubmitButton}
                  label={submitBtnTitle ? submitBtnTitle : "Ok"}
                >
                  {loading ?
                    <CircularProgress
                      size={14}
                      thickness={3}
                      color="inherit"
                    /> :
                    submitBtnIcon ? submitBtnIcon : <CheckOutlinedIcon />}
                </Button>
              }
            </DialogActions>
          </>
        }
      </Dialog>
    </>
  );
}

export default CustomDialog;