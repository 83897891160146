import React, { useState } from 'react';
import CustomDialog from '../../utils/CustomDialog';
import { baseButtonStyle } from '../Styles';
import { Button } from '@mui/material';

const DisableMonitoringButton = ({ buttonLabel, dialogTitle, dialogContent, onConfirm }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    onConfirm();
    handleClose();
  };

  return (
    <>
      <Button
        label={buttonLabel}
        size="medium"
        sx={{...baseButtonStyle()}}
        onClick={handleClickOpen}
      >
        {buttonLabel}
      </Button>      
      <CustomDialog
        openDialog={open}
        closeDialog={handleClose}
        title={dialogTitle}
        content={dialogContent}
        handleSubmit={handleConfirm}
        submitBtnTitle="Confirm"
        hideDivider={true}
      />
    </>
  );
};

export default DisableMonitoringButton;
