import { Grid, List, ListItem, ListItemText } from "@mui/material";
import { accordion, iconAction, padding0, padding2, textColor } from "../Styles";
import CustomAccordian from "../../utils/CustomAccordian";
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import React, { useEffect } from "react";
import { isEmpty, useDataProvider, useNotify } from "ra-core";
import { useFormContext } from "react-hook-form";
import { Button } from "ra-ui-materialui";
import { parsePersonaTemplateObjectToList } from "../../mappers/IdentityTemplate";
import PersonaTemplateDialog from "./PersonaTemplateDialog";

const PersonaTemplateAccordian = (props) => {

  const [mappedTemplates, setMappedTemplates] = React.useState([]);
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const form = useFormContext();

  useEffect(() => {
    fetchMappedTemplates(props.clientId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.clientId]);

  const fetchMappedTemplates = (clientId) => {
    if(!isEmpty(clientId)) {
      dataProvider
        .getPersonaTemplatesForClient({ clientId })
        .then(({ data }) => {                   
          setMappedTemplates(data);
        })
        .catch((error) => {
          notify('Something went wrong while fetching mapped identity templates.', { type: 'warning', autoHideDuration: 1000 });
        });
    }
  }

  const deleteTemplateMapping = (templateId, clientId) => {
    dataProvider
      .unMapPersonaTemplate({ clientId, templateId })
      .then(({ json }) => {
        const newTemplateList = mappedTemplates.filter(t => t.templateId !== templateId);
        notify('Identity template was removed successfully for this client', { type: 'info', autoHideDuration: 1000 });
        setMappedTemplates(newTemplateList);
        form.setValue("personaTemplates", parsePersonaTemplateObjectToList(newTemplateList));
      })
      .catch((error) => {
        notify('Something went wrong while removing the identity template', { type: 'warning', autoHideDuration: 1000 });
      });
  }
  
  return(
  <ListItem sx={padding2.y} key="personaTemplateContainer">
    <Grid container>
      <Grid item xs={12} md={4} sx={{ ...textColor.gray, marginTop: { md: props.isShow ? "10px" : "18px" } }}>Identity Templates</Grid>
      <Grid xs={12} md={props.isShow ? 6 : 5} lg={6}>
        <CustomAccordian title={"Client Identity Templates"} isShow={props.isShow} >                    
          <List sx={padding0.y}>
            {mappedTemplates
            ?.sort((a,b) => b.defaultForClient - a.defaultForClient)
            ?.map((template) => {
              return(
                <ListItem sx={accordion.listItem} key={template.templateId}>
                  <ListItemText primary={template?.defaultForClient ? template.templateName + " (Default)" : template.templateName} disableTypography sx={template?.defaultForClient ? { fontWeight : "bold" } : {}}/>
                  {!props.isShow &&
                    <Button
                      sx={iconAction}
                      size="medium"
                      onClick={() => deleteTemplateMapping(template.templateId, props.clientId)}
                    >
                      <DeleteOutlinedIcon/>
                    </Button>
                  }
                </ListItem>
              )
            })}
          </List>
        </CustomAccordian>
      </Grid>
      <Grid item xs={12} md={3} lg={2}>
        {!props.isShow && 
          <PersonaTemplateDialog 
            mappedTemplates={mappedTemplates}
            clientId={props.clientId}
            setMappedTemplates={setMappedTemplates}
            isEdit={props.isEdit}   
          />
        }
      </Grid>
    </Grid>
  </ListItem>);
}

export default PersonaTemplateAccordian;