import { isEmpty } from "ra-core";

export function parsePersonaTemplateChoices(data) {

  data.forEach(element => {                      
    element["name"] = element["templateName"];
    element["description"] = element['templateDescription'] ? element['templateDescription'] : element['templateName'];
          
    delete element["templateName"];                  
    delete element["personaTemplateId"];                        
    delete element["defaultForClient"];                              
  });
  return data;
}

export function parsePersonaTemplatesForAssignment(templates, defaultPersonaTemplate) {    
  return JSON.stringify({
    identityTemplates: templates.map(template => ({
      id: template,
      defaultForClient: template === defaultPersonaTemplate
    }))
  });
}

export function parsePersonaTemplatesForClient(templates){
  return templates.map((template) => {
    template['name'] = isEmpty(template['templateDisplayName']) ? template['templateName'] : template['templateDisplayName'];
    template['templateId'] = template['id'];
    template['description'] = template['templateDescription'] ? template['templateDescription'] : template['templateName'];

    delete template['personaTemplateId'];
    return template;
  })
}

export function parsePersonaTemplateObjectToList(data) {
  return data.map( element => element.templateId);                
}