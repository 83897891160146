import React, { useState } from "react";
import _ from 'lodash';
import Button from "@mui/material/Button";
import CustomDialog from "./CustomDialog";
import CSVSelector from "../components/common/CSVSelector";
import ReportAndFileSelector from "../components/common/ReportAndFileSelector";
import {
  REPORT_FORMATS,
  REPORT_FORMATS_CSV,
  REPORT_FORMATS_CSV_ZIP,
  REPORT_FORMATS_CSV_FILTER,
  REPORT_STATUSES,
  INSIGHTS_REPORT_TYPE,
  EVENT_REASON, ActionTitles,
  REPORT_TYPES,
  RESOURCES,
  IDENTITY_VERIFICATION_REQUEST_STATUS_KEYS,
} from '../components/Constants'
import { httpClient, fileHttpClient, isAdminUser } from "../components/DataProvider";
import { isCsvEnabled, isCsvMultiFormatEnabled, isExcelEnabled, isPdfEnabled, trimFileNameForDownload } from "./util";
import { linkButton } from '../components/Styles'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Grid } from '@mui/material'

const apiUrl = process.env.REACT_APP_API_ENDPOINT;

function DownloadLink(props) {
  const {
    clientId,
    clientRequestId,
    startDate,
    endDate,
    businessName,
    descriptionOfMatter,
    contactName,
    requestId,
    reportType,
    status,
    onBehalfOfId,
    columns,
    resource,
  } = props;
  const [disableLink, setDisableLink] = useState({
    [REPORT_FORMATS.CSV]: false,
    [REPORT_FORMATS.PDF]: false,
    [REPORT_FORMATS.EXCEL]: false
  });
  const [openCsvSelect, setOpenCsvSelect] = useState(false);
  const [csvType, setCsvType] = useState(REPORT_FORMATS.CSV);
  const [openPdfReportAndFileSelect, setOpenPdfReportAndFileSelect] = useState(false);
  const [openExcelReportAndFileSelect, setOpenExcelReportAndFileSelect] = useState(false);
  const [reportData, setReportData] = useState({});
  const [riskInsightReportData, setRiskInsightReportData] = useState("");
  const [selectedFiles, setSelectedFiles] = useState({});
  const [supplementaryFiles, setSupplementaryFiles] = useState([]);
  const [reportFilename, setReportFilename] = useState([]);
  const [riskInsightReportFilename, setRiskInsightReportFilename] = useState("");

  const isReportAvailable = ((status === REPORT_STATUSES.AVAILABLE) || (resource === RESOURCES.IDENTITY_VERIFICATION && status === IDENTITY_VERIFICATION_REQUEST_STATUS_KEYS.COMPLETED));    
  const hasSupplementaryFiles = (supplementaryFiles) => (supplementaryFiles && Object.keys(supplementaryFiles).length !== 0);
  const pdfEnable = isPdfEnabled(reportType);
  const excelEnable = isExcelEnabled(reportType);
  const csvEnable = isCsvEnabled(reportType);
  const csvMultiFormatEnable = isCsvMultiFormatEnabled(reportType);

  const handleDownload = async (reportFormat) => {
    if (reportFormat) {
      setDisableLink({ ...disableLink, [reportFormat]: true });
      await getReportData(reportFormat).then(reportData => {
        if (!_.isEmpty(reportData)) {
          //Report Filename
          const toDate = reportData.reportParams && JSON.parse(reportData.reportParams).endDate
            ? JSON.parse(reportData.reportParams).endDate
            : endDate;
          const filename = generateReportFilename(reportFormat, toDate);
          setReportFilename(filename);

          if (reportData.riskInsightReportData !== "") {
            setRiskInsightReportFilename("Risk Insight Report - " + filename);
          }

          if (reportFormat === REPORT_FORMATS.PDF && (hasSupplementaryFiles(reportData.supplementaryFiles) || reportData.riskInsightReportData !== "")) {
            //Supplementary files
            const files = Object.keys(reportData.supplementaryFiles).flatMap(accountId => reportData.supplementaryFiles[accountId]);
            setSupplementaryFiles(files);

            handlePDFOpen();
          } else if (reportFormat === REPORT_FORMATS.EXCEL && reportType === REPORT_TYPES.CLAIMS_REVIEW && hasSupplementaryFiles(reportData.supplementaryFiles)) {
            //Supplementary files
            const files = Object.keys(reportData.supplementaryFiles).flatMap(accountId => reportData.supplementaryFiles[accountId]);
            setSupplementaryFiles(files);

            handleExcelOpen();
          } else {
            generateReportDownloadLink(reportFormat, reportData.formattedReportData, filename);
          }
        }
      });
      setDisableLink({ ...disableLink, [reportFormat]: false });
    }
  };

  const generateReportDownloadLink = (reportFormat, base64ReportData, filename) => {
    const isExcel = (reportFormat === REPORT_FORMATS.EXCEL);
    const isCsv = reportFormat in REPORT_FORMATS_CSV;
    const isZip = reportFormat in REPORT_FORMATS_CSV_ZIP;
    let url = `${isCsv ? (isZip ? "data:application/zip;base64" : "data:text/csv;charset=utf-8") :
      (isExcel ? "data:application/xlsx;base64" : "data:application/pdf;base64")
      },${base64ReportData ? encodeURIComponent(base64ReportData) : ""}`;
    generateLink(url, filename);
  }

  const generateFileDownloadLink = (filename, fileType, fileData) => {
    const blob = new Blob([fileData], { type: fileType });
    const url = URL.createObjectURL(blob);
    const prefixedFilename = `${businessName || descriptionOfMatter || contactName}-${filename}`;
    generateLink(url, prefixedFilename);
  }

  const generateLink = (url, filename) => {
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    setTimeout(function () {
      window.URL.revokeObjectURL(link);
    }, 200);
  }

  const getReportData = async (reportFormat) => {
    const isAdmin = isAdminUser();
    if (reportFormat) {
      let url = isAdmin
        ? `${apiUrl}/admin/report-request/client/${clientId}/report-data/${clientRequestId}?format=${reportFormat}`
        : `${apiUrl}/api/report-request/${clientRequestId}?format=${reportFormat}`;

      let reportData = {};
      await httpClient(url)
        .then(({ json }) => {
          if (json.data && json.data.reportData) reportData = json.data.reportData;;
        });

      let riskInsightUrl = isAdmin
        ? `${apiUrl}/admin/report-request/${clientRequestId}/client/${clientId}/insights-report/${INSIGHTS_REPORT_TYPE.RISK_INSIGHTS}?format=${reportFormat}`
        : `${apiUrl}/api/report-request/${clientRequestId}/insights-report/${INSIGHTS_REPORT_TYPE.RISK_INSIGHTS}?format=${reportFormat}`;

      reportData.riskInsightReportData = "";
      if (reportFormat === REPORT_FORMATS.PDF) {
        await httpClient(riskInsightUrl)
          .then(({ json }) => {
            if (json.data && json.data.reportData && json.data.reportData.formattedReportData) {
              reportData.riskInsightReportData = json.data.reportData.formattedReportData;
            }
          });
      }

      setRiskInsightReportData(reportData.riskInsightReportData);
      setReportData(reportData);
      return reportData;
    }
  }

  const generateReportFilename = (reportFormat, reportEndDate) => {
    const isExcel = reportFormat === REPORT_FORMATS.EXCEL;
    const isCsv = reportFormat in REPORT_FORMATS_CSV;
    const isZip = reportFormat in REPORT_FORMATS_CSV_ZIP;

    const opts = { year: "numeric", month: "short", day: "numeric" };
    const fromDate = startDate
      ? new Date(startDate)
        .toLocaleDateString("en-GB", opts)
        .replace(/\s/g, "")
      : "";
    const toDate = reportEndDate
      ? new Date(reportEndDate).toLocaleDateString("en-GB", opts).replace(/\s/g, "")
      : "";

    const fileNamePrefix = `${businessName || descriptionOfMatter || contactName}`;
    const trimmedFileNamePrefix = trimFileNameForDownload(fileNamePrefix);
    const fileExtension = isCsv ? (isZip ? "zip" : "csv") : (isExcel ? "xlsx" : "pdf");
    return `${trimmedFileNamePrefix}${fromDate ? "-" + fromDate : ""}${toDate ? "-" + toDate : ""}-${requestId}.${fileExtension}`;
  }

  const getFileData = async (fileDownloadUri) => {
    return await fileHttpClient(fileDownloadUri + (isAdminUser() ? `?mode=admin&clientId=${(onBehalfOfId) ? onBehalfOfId : clientId}` : ''))
      .then(result => result);
  }

  const handleDialogSubmit = (reportFormat) => {
    switch (reportFormat) {
      case REPORT_FORMATS.CSV:
        handleCsvSelect();
        break;
      case REPORT_FORMATS.PDF:
      case REPORT_FORMATS.EXCEL:
        handleReportAndSupplementaryFileSelect(reportFormat);
        break;
      default:
        break;
    }
  }

  const handleReportAndSupplementaryFileSelect = async (reportFormat) => {
    if (!selectedFiles.hasOwnProperty(reportFormat) || selectedFiles[reportFormat] === true) {
      generateReportDownloadLink(reportFormat, reportData.formattedReportData, reportFilename);
    }
    if (riskInsightReportData !== "" && (!selectedFiles.hasOwnProperty(riskInsightReportFilename) || selectedFiles[riskInsightReportFilename] === true)) {
      generateReportDownloadLink(reportFormat, riskInsightReportData, riskInsightReportFilename);
    }
    for (const file of supplementaryFiles) {
      if (!selectedFiles.hasOwnProperty(file.fileName) || selectedFiles[file.fileName] === true) {
        let fileData = await getFileData(file.fileDownloadUri).then(result => result);
        generateFileDownloadLink(file.fileName, file.fileType, fileData);
      }
    }
    setSelectedFiles({}); //Reset selected files
    if (reportFormat === REPORT_FORMATS.PDF)
      handlePDFClose();
    else if (reportFormat === REPORT_FORMATS.EXCEL)
      handleExcelClose();
  };

  const handleCsvSelect = () => {
    handleDownload(csvType);
    handleCSVClose();
  };
  const handleCSVOpen = () => {
    setDisableLink({ ...disableLink, [REPORT_FORMATS.CSV]: true });
    setOpenCsvSelect(true);
  };
  const handleCSVClose = (event, reason) => {
    if (reason !== EVENT_REASON.BACKDROP_CLICK) {
      setOpenCsvSelect(false);
      setDisableLink({ ...disableLink, [REPORT_FORMATS.CSV]: false });
    }
  };

  const handlePDFOpen = () => {
    setDisableLink({ ...disableLink, [REPORT_FORMATS.PDF]: true });
    setOpenPdfReportAndFileSelect(true);
  };

  const handlePDFClose = (event, reason) => {
    if (reason !== EVENT_REASON.BACKDROP_CLICK) {
      setOpenPdfReportAndFileSelect(false);
      setDisableLink({ ...disableLink, [REPORT_FORMATS.PDF]: false });
    }
  };

  const handleExcelOpen = () => {
    setDisableLink({ ...disableLink, [REPORT_FORMATS.EXCEL]: true });
    setOpenExcelReportAndFileSelect(true);
  };

  const handleExcelClose = (event, reason) => {
    if (reason !== EVENT_REASON.BACKDROP_CLICK) {
      setOpenExcelReportAndFileSelect(false);
      setDisableLink({ ...disableLink, [REPORT_FORMATS.EXCEL]: false });
    }
  };

  if (!isReportAvailable)
    return ("--");

  return (
    <Grid id={clientRequestId} display="flex" flexDirection={columns ? 'column' : 'row'}>
      {csvEnable && (
        <Grid>
          <Button
            sx={linkButton}
            color="primary"
            onClick={() =>
              csvMultiFormatEnable
                ? handleCSVOpen()
                : handleDownload(REPORT_FORMATS.CSV)
            }
            disabled={disableLink[csvType]}
          >
            CSV
          </Button>
          <CustomDialog
            title="Select CSV Format"
            content={
              <CSVSelector
                choices={REPORT_FORMATS_CSV_FILTER}
                defaultValue={REPORT_FORMATS.CSV}
                onChange={(event) => setCsvType(event.target.value)}
              />
            }
            openDialog={openCsvSelect}
            closeDialog={handleCSVClose}
            dialogPaperProps={{
              style: {
                maxWidth: "224px"
              },
            }}
            handleSubmit={() => handleDialogSubmit(REPORT_FORMATS.CSV)}
          />
        </Grid>
      )}
      {pdfEnable && (
        <Grid>
          <Button
            sx={linkButton}
            color="primary"
            onClick={() => handleDownload(REPORT_FORMATS.PDF)}
            disabled={disableLink[REPORT_FORMATS.PDF]}
          >
            PDF
          </Button>
          <CustomDialog
            title="Download Report PDF and Supplementary Files"
            submitBtnTitle={ActionTitles.DOWNLOAD}
            submitBtnIcon={<FileDownloadOutlinedIcon />}
            content={
              <ReportAndFileSelector
                reportFormat={REPORT_FORMATS.PDF}
                reportFilename={reportFilename}
                riskInsightReportFilename={riskInsightReportFilename}
                supplementaryFiles={supplementaryFiles}
                onReportChange={(event) => setSelectedFiles({ ...selectedFiles, [REPORT_FORMATS.PDF]: event.target.checked })}
                onRiskInsightReportChange={(event) => setSelectedFiles({ ...selectedFiles, [riskInsightReportFilename]: event.target.checked })}
                onFileChange={(event, fileName) => setSelectedFiles({ ...selectedFiles, [fileName]: event.target.checked })}
              />
            }
            openDialog={openPdfReportAndFileSelect}
            closeDialog={handlePDFClose}
            handleSubmit={() => handleDialogSubmit(REPORT_FORMATS.PDF)}
          />
        </Grid>
      )}
      {excelEnable && (
        <Grid>
          <Button
            sx={linkButton}
            color="primary"
            onClick={() => handleDownload(REPORT_FORMATS.EXCEL)}
            disabled={disableLink[REPORT_FORMATS.EXCEL]}
          >
            EXCEL
          </Button>
          <CustomDialog
            title="Download Excel Report and Supplementary Files"
            submitBtnTitle={ActionTitles.DOWNLOAD}
            submitBtnIcon={<FileDownloadOutlinedIcon />}
            content={
              <ReportAndFileSelector
                reportFormat={REPORT_FORMATS.EXCEL}
                reportFilename={reportFilename}
                riskInsightReportFilename={riskInsightReportFilename}
                supplementaryFiles={supplementaryFiles}
                onReportChange={(event) => setSelectedFiles({ ...selectedFiles, [REPORT_FORMATS.EXCEL]: event.target.checked })}
                onRiskInsightReportChange={(event) => setSelectedFiles({ ...selectedFiles, [riskInsightReportFilename]: event.target.checked })}
                onFileChange={(event, fileName) => setSelectedFiles({ ...selectedFiles, [fileName]: event.target.checked })}
              />
            }
            openDialog={openExcelReportAndFileSelect}
            closeDialog={handleExcelClose}
            handleSubmit={() => handleDialogSubmit(REPORT_FORMATS.EXCEL)}
          />
        </Grid>
      )}
    </Grid>
  );
}
export default DownloadLink;
