import { Divider, Grid, ListItem } from "@mui/material";
import { canFieldBeHidden } from "../../utils/CreateReportUtils";
import { padding2, textColor, width } from "../Styles";
import { SelectInput } from "ra-ui-materialui";
import { useFormContext, useWatch } from 'react-hook-form';
import { isPersonaTemplateEnabled, requiredValidation } from "../../utils/util";
import { useEffect } from "react";
import isEmpty from "lodash/isEmpty"
import { isNull, isUndefined } from "lodash";
import { TooltipDescription } from "../common/TooltipDescription";

export const PersonaTemplateComponent = (props) => {

  const { personaTemplateField, personaTemplates, selectedTemplateId } = props;

  const possibleOptions = personaTemplateField?.possibleValues && personaTemplates?.filter(pt => personaTemplateField.possibleValues.includes(pt.name))
  const defaultValue = personaTemplates?.filter(pt => pt.defaultForClient)[0]?.id;
  const defaultValueFromTemplate = personaTemplateField?.defaultValue && personaTemplates?.filter(pt => !personaTemplateField.defaultValue.localeCompare(pt.name))[0]?.id;
  const required = isUndefined(personaTemplateField?.required) || isNull(personaTemplateField?.required) ? true :personaTemplateField?.required;
  const values = useWatch();
  const form = useFormContext();

  useEffect(() => {
    isPersonaTemplateEnabled(values.reportType) 
      ? form.setValue("personaTemplate", defaultValue ? defaultValue: defaultValueFromTemplate) 
      : form.setValue("personaTemplate", undefined);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[values.reportType, selectedTemplateId, defaultValue, defaultValueFromTemplate]);

  return( isPersonaTemplateEnabled(values.reportType) &&
    <Grid sx={{
      ...canFieldBeHidden(
        personaTemplateField?.isHidden,
        required,
        defaultValue || defaultValueFromTemplate
      ) ? { display: "none" } : {}
    }}>
      <ListItem sx={padding2.y}>
        <Grid container alignItems="center">
          <Grid item xs={12} md={4} sx={textColor.gray}>
            {personaTemplateField?.displayName ? personaTemplateField.displayName : "Identity Verification Template"}
            {required && <span style={{ ...textColor.red, marginLeft: "3px" }}>*</span>}
          </Grid>
          <Grid item xs={12} md={6} lg={5}>
            <SelectInput
              source="personaTemplate"
              name={personaTemplateField?.name ? personaTemplateField.name : "personaTemplate"}
              choices={!isEmpty(possibleOptions) ? possibleOptions : personaTemplates}
              label={personaTemplateField?.placeholder ? personaTemplateField.placeholder : "Choose an option"}
              variant="outlined"
              size="small"
              sx={{ ...width.fluid.w100, marginBottom: "-15px" }}                        
              defaultValue={defaultValue ? defaultValue : defaultValueFromTemplate}
              validate={required && requiredValidation}
              optionText={<TooltipDescription />}
            />
          </Grid>
        </Grid>
      </ListItem>
      <Divider light />
    </Grid>
    );
  };