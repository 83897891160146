import { useState, useEffect } from "react";
import {
  Filter,
  TextInput,
  useDataProvider,
  Loading,  
  AutocompleteInput,
  SelectInput,
  BooleanInput,
  useListContext,
} from 'react-admin'
import { ActionTitles, REPORT_STATUS_FILTER } from "../Constants";
import React from "react";
import { parseRequestingUserNameChoicesForAdmins, parseRequestingUserNameChoicesForClients } from "../../mappers/Report";
import { width } from '../Styles'
import { Error404 } from "../errors/Error404";

export const ReportsFilter = (props) => {
  return (
    <Filter {...props}>
      <TextInput
        label="Search"
        source="searchValue"
        size="small"
        variant="outlined"
        alwaysOn
        resettable
        sx={width.fixed.w200}
      />
      <SelectInput
        label="Status"
        source="status"
        choices={REPORT_STATUS_FILTER}
        size="small"
        variant="outlined"
        alwaysOn
      />
      <AutocompleteInput
        label="Requesting User Name"
        source="requestingUserId"
        choices={parseRequestingUserNameChoicesForClients(props.requestingUserNames)}
        variant="outlined"
        size="small"
        sx={width.fixed.w250}
        resettable
        alwaysOn
      />
      <BooleanInput
        label="Show Hidden Reports"
        source="hidden"
        size="medium"
        variant="outlined"
        alwaysOn
      />
    </Filter>
  );
};

export const ReportsAdminFilter = (props) => {
  const { setClientId } = props;
  var partnerChoices = [];
  const dataProvider = useDataProvider();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const { filterValues, setFilters } = useListContext();

  //When client is changed, reset requestingUserId
  useEffect(() => {
    setClientId(filterValues.clientId)          
    setFilters({ ...filterValues, "requestingUserId": '' }, true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[filterValues.clientId]);

  useEffect(() => {
    dataProvider
      .getClientNameList("admin/client/names")
      .then(({ data }) => {
        setData(data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, [dataProvider]);

  if (loading) return <Loading />;
  if (error) return <Error404 />;
  partnerChoices = data;

  return (
    <Filter variant="outlined" {...props}>
      <AutocompleteInput
        source="clientId"
        name="clientId" //check this while integration
        variant="outlined"
        size="small"
        label={ActionTitles.CORPORATE_PARTNER}
        choices={partnerChoices}
        resettable
        sx={width.fixed.w200}
        alwaysOn
      />
      <TextInput
        label="Search"
        source="searchValue"
        variant="outlined"
        alwaysOn
        resettable
        sx={width.fixed.w200}
      />
      <SelectInput
        label="Status"
        source="status"
        variant="outlined"
        choices={REPORT_STATUS_FILTER}
        alwaysOn
      />
      <AutocompleteInput
        label="Requesting User Name"
        source="requestingUserId"
        choices={parseRequestingUserNameChoicesForAdmins(props.requestingUserNames)}
        variant="outlined"
        size="small"
        sx={width.fixed.w250}
        resettable
        alwaysOn
      />
      <BooleanInput
        label="Show Hidden Reports"
        source="hidden"
        variant="outlined"
        size="medium"
        alwaysOn
      />
    </Filter>
  );
};