import { Button, Datagrid, FilterForm, FunctionField, Pagination, SelectField, SelectInput, Show, SimpleShowLayout, TextField } from "ra-ui-materialui";
import { useParams } from "react-router";
import ListActions from "../common/ListActions";
import { ActionTitles, MATCH_EVENT_TYPES_CHOICES, MATCH_EVENT_TYPES_NAMES, RESOURCES, RESOURCES_BASEPATH, SCREENING_REPORT_TYPES_DISPLAY_NAME, SUB_RESOURCES } from "../Constants";
import { contentCenter, dataGrid, float, iconAction, listRoot, padding0, padding1, textColor } from "../Styles";
import { Divider, Grid, Link, List, ListItem, ListItemText, Typography } from "@mui/material";
import { IDListItem } from "./IdentityRequestShow";
import { ListContextProvider, useDataProvider, useList, useNotify, useRecordContext, useRefresh } from "ra-core";
import CheckCircleIcon from "@mui/icons-material/CheckCircleOutlined";
import CancelIcon from "@mui/icons-material/CancelOutlined";
import { cloneDeep, defaultsDeep, isEmpty } from "lodash";
import { useState } from "react";
import CustomDialog from "../../utils/CustomDialog";
import VisibilityIcon from "@mui/icons-material/VisibilityOutlined";
import CustomTooltip from "../../utils/CustomTooltip";
import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';
import DisableMonitoringButton from "./DisableMonitoringButton";
import { isAdminUser } from "../DataProvider";

export const ViewScreeningReport = () => {
  const { requestId, reportId, clientId } = useParams();  
  const isAdmin = isAdminUser();
  
  return (
    <Show
      actions={
        <ListActions
          title={ActionTitles.IDENTITY_VERIFICATION_SCREENING_REPORT}
          showViewButton={true}
          showDeleteButton={false}
          resource={RESOURCES.IDENTITY_VERIFICATION}
          basePath={RESOURCES_BASEPATH.IDENTITY_VERIFICATION}
          record={isAdmin ? { id : `client/${clientId}/identity-verification/${requestId}`} : { id: requestId }}          
        />
      }
      id={reportId}
      renderButtonAndSearch={false}
      sx={contentCenter}
      resource={SUB_RESOURCES.SCREENING_REPORT}
      queryOptions={ isAdmin ? { meta : { clientId, requestId }} : { meta: { requestId }}}
    >
      <SimpleShowLayout>
        <ScreeningReportShowGrid id={requestId} reportId={reportId} isAdmin={isAdmin} />
      </SimpleShowLayout>
    </Show>
  );
}

export const ScreeningReportShowGrid = (props) => {
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  const { id, reportId, isAdmin } = props;
  
  const pauseContinuousMonitoring = () => {
    dataProvider
    .pauseContinuousMonitoring({ id, reportId })
    .then(() => {      
      notify('Continuous monitoring disabled', "info", { autoHideDuration: 1000 });
      refresh();
    })
    .catch(() => {
      notify('Something went wrong while disabling continuous monitoring', "warning", { autoHideDuration: 1000 });
    });
  }

  return(
    <Grid container flexGrow={1}>
      <Grid item xs={12}>
        <List sx={padding0.y}>
          <IDListItem 
            title="Report type" 
            value={SCREENING_REPORT_TYPES_DISPLAY_NAME[record['reportType']]} 
          />          
          <Divider light />
          <IDListItem title="Match Occured" value={
            record['hasMatch'] ?
            (<CheckCircleIcon sx={{ ...textColor.green, ...float.left }} />) :
            (<CancelIcon sx={{ ...textColor.red, ...float.left }} />)
          } />
          <Divider light />          
          <IDListItem title="Monitoring Enabled" value={
            record['continuousMonitoringEnabled'] ?
            (<CheckCircleIcon sx={{ ...textColor.green, ...float.left }} />) :
            (<CancelIcon sx={{ ...textColor.red, ...float.left }} />)
          } />
          <Divider light />
          <IDListItem title="Created" value={record["created"]} />  
          <Divider light />
          <IDListItem title="Last Modified" value={record["lastModified"]} />  
          <Divider light />
          {!isEmpty(record['events']) && <EventList data={record['events']} id={id} reportId={reportId} isAdmin={isAdmin} />}          
          {!isAdmin && 
          <ListItem sx={padding1.y}>
            <Grid container justifyContent="flex-end">          
              <Grid container item xs={12} lg={10} justifyContent="flex-end">              
                <Grid item xs={12} md={4} lg={3} container>
                  <DisableMonitoringButton 
                    buttonLabel="DISABLE MONITORING"
                    dialogTitle="Disable Continuous Monitoring"
                    dialogContent={<p>Are you sure you want to disable continuous monitoring for this report?</p>}
                    onConfirm={pauseContinuousMonitoring}              
                  />
                </Grid>              
              </Grid>
            </Grid>
          </ListItem>}
        </List>
      </Grid>      
    </Grid>
  );
};

export const EventList = ({ data, id, reportId, isAdmin }) => {    
  const listContext = useList({ 
    data, 
    perPage: 5, 
    page: 1, 
    sort: { field: 'lastModified', order: 'DESC' }
  });
  
  const [open, setOpen] = useState(false);
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();

  const handleDialogClose = () => {      
    setOpen(false);      
  };

  const dismissMatch = ({ eventId }) => {
    dataProvider
    .acknowledgeScreeningReport({ id, reportId, eventId })
    .then(() => {
      notify('Match acknowledged successfully', "info", { autoHideDuration: 1000 });
      refresh();
    })
    .catch(() => {
      notify('Something went wrong while acknowledging match', "warning", { autoHideDuration: 1000 });
    })
    .finally(() => {
      handleDialogClose();
    });
  }

  const filters = [
    <SelectInput 
      source="q" 
      alwaysOn 
      label="Status"    
      size="small"
      variant="outlined"
      choices={MATCH_EVENT_TYPES_CHOICES}    
    />
];

  return(
    <ListContextProvider value={listContext}>
      <Grid>
        <Grid item xs={12}>
          <FilterForm filters={filters} />
        </Grid>        
        <Datagrid        
          sx={{
            ...defaultsDeep(
              cloneDeep(dataGrid.base),
              cloneDeep(dataGrid.lastColActions),
              cloneDeep(dataGrid.firstColSticky)
            ),
            ...listRoot["& .RaList-content"]
          }}
          bulkActionButtons={false}
          empty={null}
        >          
          <TextField source="name" />
          <TextField source="birthRecord" />          
          <SelectField source="status" choices={MATCH_EVENT_TYPES_CHOICES} />                                                                  
          <TextField source="lastModified" />          
          <TextField source="reviewedBy" />
          <TextField source="reviewedAt" />
          <FunctionField
            source="View"
            label={false}
            sortable={false}
            render={(record) => {
              return (
                <>
                <CustomTooltip
                  title="View"
                  placement="top"
                >
                <Button
                  sx={iconAction}
                  size="medium"
                  onClick={() => {                    
                    setOpen(true);
                  }}
                >
                  <VisibilityIcon />
                </Button>
                </CustomTooltip>
                <CustomDialog 
                  openDialog={open}
                  closeDialog={handleDialogClose}
                  title="Match Details"
                  submitBtnTitle="Acknowledge Match"
                  submitBtnIcon={<BlockOutlinedIcon />}
                  dialogPaperProps={{ fullScreen : true, fullWidth : true }}
                  handleSubmit={() => dismissMatch({ eventId: record.id })}
                  content={
                    <EventGrid event={record} />
                  }
                  hideSubmitBtn={isAdmin}
                />
                </>
              );
            }}
          />                    
        </Datagrid>
        <Grid sx={dataGrid.pagination}>
          <Pagination sx={float.left} />
        </Grid>
      </Grid>      
    </ListContextProvider>
  );
};

export const EventGrid = ({ event }) => {
  return (
    <Grid container flexGrow={1}>
      <Grid item xs={12}>
        <List sx={padding0.y}>
          <IDListItem title="Name" value={event.name} />
          <Divider light />
          <IDListItem title="Birth Record" value={event.birthRecord} />
          <Divider light />
          <IDListItem title="Status" value={MATCH_EVENT_TYPES_NAMES[event.status]} />
          <Divider light />
          <IDListItem title="Summaries" value={            
            <List sx={padding0.y}>
              {event.summaries?.map((summary, index) => (
                <ListItem key={index} sx={{ padding: '4px 0', listStyleType: 'disc', display: 'list-item' }}>
                  <ListItemText primary={summary} disableTypography />
                </ListItem>
              ))}
            </List>
          }/>
          <Divider light />          
          <IDListItem title="Sources" value={
            <List sx={padding0.y}>
              {event.sources?.map((source, index) => (
                <ListItem key={index} sx={{ padding: '4px 0', listStyleType: 'disc', display: 'list-item' }}>
                  <ListItemText 
                    primary={source.referenceName} 
                    secondary={                      
                      <Typography noWrap>
                        <Link href={source.referenceUrl} target="_blank" rel="noopener noreferrer">
                          {source.referenceUrl}
                        </Link>
                      </Typography>
                    } 
                    disableTypography
                  />
                </ListItem>
              ))}
            </List>
          } />
          <Divider light />
          {/*<IDListItem title="Preview" value={
            <Button
              sx={{...simpleButton}}
              label="Preview"
              size="small"
            />              
          } />
          <Divider light />
          <IDListItem title="Download" value={
            <Button
              sx={{...simpleButton}}
              label="Download"
              size="small"
            />              
          }/>
          <Divider light />*/}
          <IDListItem title="Reviewed By" value={event.reviewedBy || "N/A"} />
          <Divider light />
          <IDListItem title="Reviewed At" value={event.reviewedAt} />
          <Divider light />          
          <IDListItem title="Created At" value={event.created} />
          <Divider light />
          <IDListItem title="Last Modified" value={event.lastModified} />
        </List>
      </Grid>
    </Grid>
  );
};